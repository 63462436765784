import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Cards from '../components/Cards'
import Loading from '../components/Loading'
import useAxios from '../utils/useAxios'

function FavouritesPage() {

  const baseURL = 'https://vips.vipsnepal.com'

  const api = useAxios()
  const [loading,setLoading] = useState(false)
  const [orgnames, setOrgnames] = useState([])
  const [orgname, setOrgname] = useState([])
  // const [array,setArray] = useState([{
  //   'id':'',
  //   'org_name':''
  // }])

  useEffect(() => {
    getOrgname()
    getOrgnames()

    // orgname.map(org => {
    //   orgnames.map((orgs)=>{
    //     if(org.id === orgs.hotel){
    //       setArray([
    //         ...array,
    //         {[array.id]:org.id,
    //         [array.org_name]:org.org_name}
    //       ]) 
    //       console.log(org.id)
    //     }
    //   })
    // })
    
  }, [])

  let getOrgnames = async () => {
    await api.get(`${baseURL}/api/myfavrioute`).then((response) => {
      setOrgnames(response.data)
      setLoading(true)
    })
  }
  let getOrgname = async () => {
    await api.get(`${baseURL}/api/customerhome`).then((response) => {
      setOrgname(response.data)
    })
  }
  


  return (
    <div className='app'>
      <h3 className='favouriteshead'>Favourites</h3>
      {loading ? <div className='cardcontainer'>
        {orgnames.map(org => (

          <Cards 
          key={org.id} 
          id={org.hotel}
          thumbnail={baseURL + org.thumbnail}
          address={org.address} 
          uuid={org.uuid}
          name={org.org_name} 
          /> 

        ))}
      </div> : <Loading /> }
      
    </div>
  )
}

export default FavouritesPage