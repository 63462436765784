import React, {useContext,useState} from 'react'
import AuthContext from '../context/AuthContext'
import image from './../Assets/img.png'
import Logo from '../components/Logo'
import { Link } from 'react-router-dom'
import RegisterPage from './RegisterPage'

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const LoginPage = () => {
    let {loginUser, loadings} = useContext(AuthContext)
    return (
    <>
        { loadings && <Box sx={{ width: '100%' }}>
             <LinearProgress />
         </Box> }
            <Logo />
          <main className='App2'>
            <div className='logoimage'>
                <img src={image} alt=""  style={{'height':'80vh'}}/>
            </div>
            {/* index.css */}
          <section className='section2'>
          <h1>Login</h1>
          <form onSubmit={loginUser} className='formcontainer'>
              <label htmlFor="username">Username:</label>
              <input
                  type="text"
                 name='username'
                 className='newinput'
                  required
              />

              <label htmlFor="password">Password:</label>
              <input
                  type="password"
                  name='password'
                 className='newinput'
                  required
              />
           <a href="https://vips.vipsnepal.com/password_reset"><span style={{'fontSize':'20px','color':'#0404f9','fontWeight':'bold'}}>Forget Password</span></a>
           {/* app.css  */}
              <button className='button newinput' style={{'margin' : '10px 0'}} >Login</button>
              <span>Don't have account?<Link to='/registerpage' element={<RegisterPage />}><span style={{'color':'red'}}> Register Now</span></Link> </span>
          </form>
         
      </section>
      <div className='footers'>
        <span>Copyright 2022 AceTech Pvt Ltd. All Rights Reserved.</span>
      </div>
      </main>
      </>
    )
}

export default LoginPage
