import React, {useState,useEffect} from 'react'
import axios from 'axios'
import useAxios from '../utils/useAxios'
import { useParams } from 'react-router';
import jwt_decode from "jwt-decode";


import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

  
  const theme = createTheme();



const Change = () => {
    const baseURL = 'https://vips.vipsnepal.com'
    const [pwd, setPwd] = useState({
      'oldpwd': '',
      'pwd1': '',
      'pwd2': ''
  })
  const [success,setSuccess] = useState(false)
  const [oldpwderr,setOldpwderr] = useState(false)
  const handleChange = (e) => {
      setPwd({
          ...pwd,
          [e.target.name]: e.target.value
      })
      setOldpwderr(false)
      setSuccess(false)
  }
  const [validMatch, setValidMatch] = useState(false)
  useEffect(() => {
      setValidMatch(pwd.pwd1 === pwd.pwd2);
  }, [pwd.pwd1, pwd.pwd2])
    
  let [authTokens, setAuthTokens] = useState(()=> localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
  const [islogged,setIslogged] = useState(()=> localStorage.getItem('authTokens') ? true : false)
  let [user, setUser] = useState(()=> localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)

  // const {changePassword} = useContext(AuthContext)
  const params = useParams()

  const changePassword = async(e) =>{
      e.preventDefault()
      let response = await fetch(baseURL+'/api/change_password/'+params.id+'/', {
          method:'PUT',
          headers:{
              'Content-Type':'application/json',
              Authorization: `Bearer ${authTokens?.access}`
          },
          body:JSON.stringify({'old_password':e.target.oldpwd.value, 'password':e.target.pwd1.value, 'password2':e.target.pwd2.value})
      })
      // let data = await response.json()
      if(response.status === 200){
          setPwd({
            'oldpwd': '',
            'pwd1': '',
            'pwd2': ''
            })
            setSuccess(true)
      }else if (response.status === 400){
          setOldpwderr(true)
          
      }
      else{
        alert('Something went wrong!')
      }
  }
  



    return (
    <>
<ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
         {success &&  <p style={{'color':'white','background':'green','fontSize':'16px','padding':'5px'}}>Password Successfully Changed</p>}
         {oldpwderr &&  <p style={{'color':'white','background':'red','fontSize':'16px','padding':'5px'}}>Failed, try entering correct password or your password is too short or common </p>}
          <Box component="form" onSubmit={changePassword}  sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="oldpwd"
                  type="password"
                  label="Old Password"
                  name="oldpwd"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="pwd1"
                  type="password"
                  label="New Password"
                  name="pwd1"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="pwd2"
                  label="Re-Enter Password"
                  type="password"
                  id="pwd2"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
         
      </>
    )
}

export default Change
