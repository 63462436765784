import React, { useEffect, useState } from 'react'
import Cards from '../components/Cards'
import Loading from '../components/Loading'
import useAxios from '../utils/useAxios'

function HomePage() {

  const baseURL = 'https://vips.vipsnepal.com'

  const [search, setSearch] = useState('')
  const [loading,setLoading] = useState(false)
  const find = (data) => {
    return data.filter((item) => item.org_name.toUpperCase().includes(search))
  }



  const api = useAxios()
  const [orgname, setOrgname] = useState([])
  useEffect(() => {
    getOrgname()
  }, [search])

  let getOrgname = async () => {
    await api.get(`${baseURL}/api/customerhome`).then((response) => {
      setOrgname(response.data)
      setLoading(true)
    })
  }

  const handleClick = (id) => {
    api.post(`${baseURL}/api/customercheckin/${id}/`).then(response =>{
    console.log('success')
    })
  }


  return (
    <div className='app'>
      <input type="search" className='allcustomerinput newinput' placeholder='Search...' onChange={(e) => setSearch(e.target.value.toUpperCase())} />
      {loading ? <div className='cardcontainer'>
        {find(orgname).map(org => (
          
           <Cards 
          key={org.id} 
          id={org.id}
          onClick={() => handleClick(org.id)}
          name={org.org_name} 
          thumbnail={org.thumbnail} 
          address={org.address} 
          uuid={org.uuid}
          /> 

        ))}
      </div> : <Loading /> }
      
    </div>
  )
}

export default HomePage