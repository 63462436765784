import React,{useEffect, useState} from 'react'
import useAxios from '../utils/useAxios'
import {useParams} from 'react-router-dom'
import Alert from '@mui/material/Alert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ImageSlider from '../components/detailpage/ImageSlider';
import TabComponent from '../components/detailpage/TabComponent'

function DetailPage() {                                                                                                                                                                                     
  const baseURL = 'https://vips.vipsnepal.com'
  const [click,setClick] = useState(false)
  const [detail,setDetail] = useState([])  
  const [readmore,setReadmore] = useState(false)   
  const toggleReadMore = () => {
    setReadmore(!readmore);
  }                                                                                                                                                                                                                              
  const params = useParams()


  const find = (data)=>{
    return data.filter((item)=>{
      return item.id == params.id
    })
  }

  const api = useAxios()
  const [orgname,setOrgname] = useState([])
  useEffect(()=>{
    getOrgname()
    getData()
  },[])

  let getOrgname = async ()=>{
     await api.get(`${baseURL}/api/customerhome`).then((response)=>{
        setOrgname(response.data)
      })
  }
  
const [fav,setFav] = useState()
const getData = ()=>{
  api.get(`${baseURL}/api/customercheckin/${params.id}`).then((response)=>{
    setDetail(response.data)
    setFav(response.data[0].favrioute)
  })
}

const submitFav = ()=>{
  setClick(true)
  setFav(!fav)
  api.put(`${baseURL}/api/favrioute/${params.id}`,{'favrioute':fav})
}


  return (
    <>
    {find(orgname).map(org=>{
      const iframeSource = org.location
      const text = org.description
      return (
    <div key={org.id} className='app3'>
         {(click && fav) && <Alert onClose={() => setClick(false)}>{org.org_name} is successfully added to Favourite</Alert>}
         {(click && !fav) && <Alert onClose={() => setClick(false)}>{org.org_name} is successfully removed from Favourite</Alert>}
         <ImageSlider />
         <div className='detailCard'>

          <div className='detailheaderdiv'>
            <div style={{'display':'flex','flexDirection':'column'}}>
               <span className='detailheader'>{org.org_name}</span>
               <p style={{'fontSize':'14px'}}>{org.address}</p>
           </div>
          <FavoriteIcon onClick={submitFav} style={fav ? {'color':'red'} : {'color':'black'}} />
          </div>

          <div className='detaildesp'>
              <p style={{'fontSize':'14px'}}>{readmore ? text : text.slice(0,150)}</p>
              <span onClick={toggleReadMore} className="read-or-hide">
                {!readmore ? "...read more" : " show less"}
              </span>
          </div>

          <div className='visitpp'>

              <div className='visit'>
                  <div className='visitdiv'>{detail[0]?.visit}</div>
                  <span className='fontvis' >Visit </span>
              </div>

              <div className='visit'>
                  <div className='purchasediv'>{detail[0]?.purchase}</div>
                  <span className='fontvis'>Purchase</span>
              </div>

              <div className='visit'>
                  <div className='pointsdiv'>{detail[0]?.purchase/100}</div>
                  <span className='fontvis' >Points</span>
              </div>

          </div>
          <TabComponent iframeSource={iframeSource} orgnameid={org.id}/>
          <div className='tabs'>

          </div>

        </div>
        

    </div>

   ) })}
      </>
  )
}

export default DetailPage