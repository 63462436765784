import React, { useState,useEffect } from 'react'
import Update from './Update'
import useAxios from '../utils/useAxios'
import { UilUser } from '@iconscout/react-unicons'



import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  ['@media screen and (max-width: 600px)']:{
    width:'100vw'
  }
};

export default function UpdateInformation({load}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const baseURL = 'https://vips.vipsnepal.com'

  const [target,setTarget] = useState({
    'name':'',
    'email':'',
    'phone_number': ''
})

  const api = useAxios()
  useEffect(()=>{
    getOrgname()
  },[])

  let getOrgname = async ()=>{
     await api.get(`${baseURL}/api/updateprofile/`).then((response)=>{
        setTarget(response.data)
        load()
      })
  }

  return (
    <>
    <div className='personalinfo' onClick={handleOpen}>
            <div className='icondiv'> 
            <UilUser />
            </div>
            <div className='titlediv'>
                <h4>{target.name}</h4>
                <h6>Personal Info</h6>
            </div>
        </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Update handleClose={handleClose} handleOpen={handleOpen} />
        </Box>
      </Modal>
      </>
  );
}
