import React,{useState,useEffect} from 'react';
import {NavLink} from 'react-router-dom'
import { UilHome,UilHeartAlt,UilPercentage,UilSetting } from '@iconscout/react-unicons'
import useAxios from '../utils/useAxios';




const FooterData = [
  {
    icon: UilHome,
    label: "Home",
    to:'/homepage/'
  },
  {
    icon: UilHeartAlt,
    label: "Favourites",
    to:'/homepage/favouritespage'
  },
  {
    icon: UilPercentage,
    label: "Offer",
    to:'/homepage/offerpage'
  }
]


export default function HomePage() {
 
  const baseURL = 'https://vips.vipsnepal.com'
  const api = useAxios()
  const [target,setTarget] = useState()
  useEffect(() => {
    getOrgname()
  }, [])

  let getOrgname = async () => {
    await api.get(`${baseURL}/api/updateprofile/`).then((response) => {
      setTarget(response.data)
    })
  }
  return (
      <div className='bottomnavigation'>
        {FooterData.map((fdata,index)=>(
            <NavLink
            to={fdata.to}
            className='menuItem'
            activeclassname='active'
            key={index}
            >
              <fdata.icon />
              <span>{fdata.label}</span>
            </NavLink>
        ))}
          <NavLink
            to={'/homepage/setting/'+target?.user}
            className='menuItem'
            activeclassname='active'
            >
              <UilSetting />
              <span>Setting</span>
            </NavLink>
      </div>




    // <BottomNavigation  className='bottomnavigation' value={value} onChange={handleChange}>
      
    //   <BottomNavigationAction
    //   component={Link}
    //   to="/homepage"
    //     label="Home"
        
    //     icon={<UilHome />}
    //   />
     
     
    //   <BottomNavigationAction
    //   component={Link}
    //   to="/homepage/favoritespage"
    //     label="Favorites"
    //     icon={<UilHeartAlt />}
    //   />
    //   <BottomNavigationAction
    //   component={Link}
    //   to='/homepage/offerpage'
    //     label="Offer"
    //     icon={<UilPercentage />}
    //   />
    //   <BottomNavigationAction component={Link} to='/homepage/setting' label="Setting" icon={<UilSetting />} />
    // </BottomNavigation>
  );
}
