import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button';

function Cards({id,name,thumbnail,address,uuid,onClick}) {
  return (
    <div className='Cards' onClick={onClick}>
      <Link to={`/homepage/${id}`}  > 
            <div className='parentContainer'>
                    <img src={thumbnail} style={{'maxWidth':'60px'}} alt="" />
            </div>
            </Link>
      <Link to={`/homepage/${id}`}  > 
            <div className='parentContainer3'>
                    <span style={{'fontSize':'17px'}}>{name}</span>
                    <span style={{'fontSize':'12px'}}>{address}</span>
            </div>
            </Link>
            <div className='parentContainer4'>
              <a href={'https://vips.vipsnepal.com/qr/myhome/'+uuid}> 
            <Button variant="outlined" sx={{fontSize:'12px',minWidth:'70px', color: 'red',border: '1px solid red', '&:hover':{background:'#e45858',color:'white',border:'2px solid red'}}}> Menu</Button>
            </a>
            </div>

        </div>
  )
}

export default Cards