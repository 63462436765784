import './new.css'
import {Routes,Route} from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from './context/AuthContext'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'
import Tutorial from './pages/Tutorial'
import RouterPage from './pages/RouterPage'
import PrivateRoute from './utils/PrivateRoute'


function App() {
  const {islogged} = useContext(AuthContext)
  return (
    <Routes>
      <Route path='/' element={!islogged ? <LoginPage /> : <Tutorial /> } />

      <Route element={<PrivateRoute />}>
      <Route path='/homepage/*' element={<RouterPage />} />
      </Route>
      
      <Route path='/registerpage' element={<RegisterPage />} />
    </Routes>
  )
}

export default App