import React  from 'react';
import { Routes, Route} from 'react-router-dom'
import HomePage from './HomePage'
import FavouritesPage from './FavouritesPage'
import OfferPage from './OfferPage'
import Setting from './Setting'
import FooterNav from '../components/FooterNav'
import DetailPage from './DetailPage';

export default function RouterPage() {
  

  return (
    <>
    <div className='new'>
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/favouritespage' element={<FavouritesPage />} />
      <Route path='/offerpage' element={<OfferPage />} />
      <Route path="/setting/:id" element={<Setting />} />
      <Route path='/:id' element={<DetailPage />} />
    </Routes>
    <FooterNav />
    </div>


    </>
  );
}
