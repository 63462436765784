import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useAxios from '../../utils/useAxios';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabComponent({iframeSource,orgnameid}) {
  const [note,setNote] = useState()
  const [value, setValue] = useState(0);
  const api = useAxios()
  const baseURL = 'https://vips.vipsnepal.com'

useEffect(()=>{
    getNotes()
},[])

  let getNotes = async () => {
    let response = await api.get('https://vips.vipsnepal.com/api/customeroffer/'+orgnameid )
     setNote(response.data)   
}

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Map" {...a11yProps(0)} />
          <Tab label="Offer" {...a11yProps(1)}  />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="googlemap" dangerouslySetInnerHTML={{__html: iframeSource}}></div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className='outertable'>
    <table className='table'>
        <thead>
            <tr>
                <th style={{'borderRadius':'20px 0 0 0 '}}>Points</th>
                <th style={{'borderRadius':'0 20px 0 0 '}}>Offer</th>
            </tr>
        </thead>
        <tbody>
            {
                note?.map((not) => (
                    <tr key={not.id}>
                        <td>{not.points}</td>
                        <td>{not.offer}</td>
                    </tr>))
            }
        </tbody>
    </table>
    </div>
      </TabPanel>
    </Box>
  );
}
