import React, {useState} from 'react'
import {Link,useNavigate} from 'react-router-dom'
import axios from 'axios'
import LoginPage from './LoginPage'

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
 
const theme = createTheme();

const RegisterPage = () => {
    const baseURL = 'https://vips.vipsnepal.com'
    const navigate = useNavigate()
    const [target,setTarget] = useState({
        'name':'',
        'username':'',
        'password':'',
        'password2':'',
        'email':'',
        'phone_number': null
    })
    const [emailerr,setEmailerr] = useState(false)
    const [emailerrs,setEmailerrs] = useState(false)
    const [usererr,setUsererr] = useState(false)
    const [pwderr,setPwderr] = useState(false)
    const [commonerr,setCommonerr] = useState(false)
    const [short,setShort] = useState(false)
    const [mobile, setmobile] = useState("");
    const [isError, setIsError] = useState(false);
    const [loading,setLoading] = useState(false)

    const handleChange = (e)=>{
      // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setTarget({
            ...target,
            [e.target.name]: e.target.value
          });
          setEmailerr(false); 
          setEmailerrs(false)
          setUsererr(false) 
          setPwderr(false)
          setCommonerr(false)
          setShort(false)
    }


    const handleSubmit =   (e)=>{
      
        e.preventDefault()
             axios.post(`${baseURL}/api/cregister/`,{'name':target.name,'username':target.username,'password':target.password,'password2':target.password2,'email':target.email,'phone_number':target.phone_number})
             .then((response)=>{
                    console.log('success');
                    setLoading(true)
                    navigate('/')
            }).catch (error =>  { 
            if(error.response.data.username?.[0] === "This field must be unique."){
                setUsererr(true)
             }
            if(error.response.data.email?.[0] === "This field must be unique."){
              setEmailerr(true)
            }
             else if(error.response.data.email?.[0] === "Enter a valid email address."){
              setEmailerrs(true)
            }
            if(error.response.data.password?.[0] === "This password is too common."){
              setCommonerr(true)
            }
            else if(error.response.data.password?.[0] === "This password is too short. It must contain at least 8 characters."){
              setShort(true)
            }
          }) 

        if(target.password !== target.password2){
          setPwderr(true)
        }
        if(mobile.length != 10 || String(mobile)[0] != 9 ){
          setIsError(true)
        }
        
      } 
            

    
    return (
    <>
<ThemeProvider theme={theme}>
{ loading && <Box sx={{ width: '100%' }}>
             <LinearProgress />
         </Box> }
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom:'2rem'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            {/* <LockOutlinedIcon /> */}
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form"  onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  onChange={handleChange}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={usererr && true}
                  id={usererr ? "outlined-error-helper-text" : "username"}
                  name="username"
                  required
                  fullWidth
                  label="Username"
                  onChange={handleChange}
                  helperText={usererr ?  "Username already exist" : ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={commonerr || short}
                  required
                  fullWidth
                  id="password"
                  type="password"
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  helperText={short ? "password must contains at least 8 character " : commonerr ? "password is too common" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  error={pwderr}
                  fullWidth
                  name="password2"
                  label="Re-Enter Password"
                  type="password"
                  id="password2"
                  onChange={handleChange}
                  helperText={pwderr ? "password doesnot match" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={(emailerr || emailerrs) && true}
                  required
                  fullWidth
                  id={emailerr || emailerrs ? "outlined-error-helper-text" : "email"}
                  type="email"
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  helperText={emailerrs ? 'Enter valid email address' : emailerr ? "Email already exist" : ''}
                  
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  error={isError}
                  name="phone_number"
                  label="Phone"
                  type="number"
                  id="phone_number"
                  onChange={ (e)=>{
                    setTarget({
                         ...target,
                         [e.target.name]: e.target.value
                       })
                    setmobile(e.target.value);
                    setIsError(false)
                  }
                }
                helperText={isError ? "Enter Valid Phone number" : ''}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
              <Link to='/' element={<LoginPage />}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
         
      </>
    )
}

export default RegisterPage
