import React, { useContext,useState} from 'react'
import AuthContext from '../context/AuthContext'
import { UilSignout } from '@iconscout/react-unicons'
import UpdateInformation from '../components/UpdateInformation'

import ChangePassword from '../components/ChangePassword';
import Loading from '../components/Loading';


function Setting() {
  const { logoutUser } = useContext(AuthContext)
  const [loading,setLoading] = useState(false)

  const load = () => {
    setLoading(true)
  }

  return (
    <div className='app2'>
      <div className='settingheader'>
      <span style={{'fontSize':'40px'}}>Settings</span >
      </div>
     <div style={{'display':'none'}}> <UpdateInformation load={() => load()}  /></div>
     {loading ? <div className='settingbody'>
        <span style={{'fontSize':'25px'}}>Account</span>

        <UpdateInformation />

        <span style={{'fontSize':'25px','marginTop':'2rem'}}>Settings</span>
        
        <ChangePassword />

        <div className='personalinfo' onClick={logoutUser}>
            <div className='icondiv3'> 
            <UilSignout />
            </div>
            <div className='titlediv'>
                <h5>Logout</h5>
            </div>
        </div>
      </div>  : <Loading />}
      
      {/* <div className='Cards2'>
        <UpdateInformation />
        <ChangePassword />

        <Button variant="contained" onClick={logoutUser} sx={{bgcolor:'red'}} >Logout</Button>
      </div> */}

    </div>
  )
}

export default Setting