import React,{useState,useEffect} from 'react'
import Loading from '../components/Loading'
import useAxios from '../utils/useAxios'

function OfferPage() {
  const baseURL = 'https://vips.vipsnepal.com'
  const api = useAxios()
  const [loading,setLoading] = useState(false)
  const [target,setTarget] = useState()
  useEffect(() => {
    getOrgname()
  }, [])

  let getOrgname = async () => {
    await api.get(`${baseURL}/api/specialoffer/`).then((response) => {
      setTarget(response.data)
      setLoading(true)
    })
  }
  return (
    <div className='app'>
      <h3 className='favouriteshead'>Offer</h3>
      {loading ? <div className='cardcontainer2'>
        {target?.map(img => (
              <img src={baseURL+img.image} key={img.id} style={{'width':'90%','margin':'0 auto'}} alt="" />
        ))}
      </div> : <Loading /> }
      
    </div>
  )
}

export default OfferPage