import React, { useState, useEffect } from 'react'
import {useNavigate} from 'react-router-dom'
import useAxios from '../utils/useAxios'
import axios from 'axios'

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme();



const Update = ({handleClose,handleOpen}) => {
  const baseURL = 'https://vips.vipsnepal.com'

  const [emailerr,setEmailerr] = useState(false)
  const [emailerrs,setEmailerrs] = useState(false)
  const [mobile, setmobile] = useState("");
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate()

  const [target, setTarget] = useState({
    'name': '',
    'email': '',
    'phone_number': ''
  })
  const handleChange = (e) => {
    setTarget({
      ...target,
      [e.target.name]: e.target.value
    })
          setEmailerr(false); 
          setEmailerrs(false)
  }


  const api = useAxios()
  useEffect(() => {
    getOrgname()
  }, [])

  let getOrgname = async () => {
    await api.get(`${baseURL}/api/updateprofile/`).then((response) => {
      setTarget(response.data)
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
      api.put(`${baseURL}/api/updateprofile/`, { 'name': target.name, 'email': target.email, 'phone_number': target.phone_number }).then((response) => {
          setTarget({
            'name': target.name, 
            'email': target.email,
            'phone_number': target.phone_number
          })
          handleClose()
      }).catch(error => {
        if(error.response.data.email?.[0] === "This field must be unique."){
          setEmailerr(true)
        }
         else if(error.response.data.email?.[0] === "Enter a valid email address."){
          setEmailerrs(true)
        }
        else{
          handleClose()
        }
        
      })
      
  }




  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    value={target.name}
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    onChange={handleChange}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={(emailerr || emailerrs) && true}
                    required
                    fullWidth
                    id={emailerr || emailerrs ? "outlined-error-helper-text" : "email"}
                    type="email"
                    label="Email"
                    name="email"
                    value={target.email}
                    onChange={handleChange}
                    helperText={emailerrs ? 'Enter valid email address' : emailerr ? "Email already exist" : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    error={isError}
                    name="phone_number"
                    value={target.phone_number}
                    label="Phone"
                    type="number"
                    id="phone_number"
                    onChange={ (e)=>{
                      setTarget({
                           ...target,
                           [e.target.name]: e.target.value
                         })
                      setmobile(e.target.value);
                      setIsError(false)
                    }
                  }
                  helperText={isError ? "Enter Valid Phone number" : ''}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>

    </>
  )
}

export default Update
