import React, { useState } from 'react'
import Change from './Change'
import { UilKeyholeCircle } from '@iconscout/react-unicons'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  ['@media screen and (max-width: 600px)']: {
    width: '100vw'
  }
};

export default function ChangePassword() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>

      <div className='personalinfo' onClick={handleOpen}>
        <div className='icondiv2'>
          <UilKeyholeCircle />
        </div>
        <div className='titlediv'>
          <h5>Change Password</h5>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Change />
        </Box>
      </Modal>
    </>
  );
}
